<template>
  <el-form v-loading="loading" ref="form" :model="form" label-width="100px">
    <el-form-item label="活动名称">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <el-form-item label="活动时间">
      <el-date-picker v-model="form.time_range" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期"></el-date-picker>
    </el-form-item>
    <el-form-item label="直接购买价格">
      <el-input-number :min="0" v-model="form.fee"></el-input-number>
      元
    </el-form-item>
    <el-form-item label="第二阶梯价格">
      <el-input-number :min="0" v-model="form.double_buy_fee"></el-input-number>
      元
    </el-form-item>
    <el-form-item label="第二阶梯人数">
      <el-input-number :min="0" v-model="form.double_buy_num"></el-input-number>
    </el-form-item>
    <el-form-item label="第三阶梯价格">
      <el-input-number :min="0" v-model="form.three_buy_fee"></el-input-number>
      元
    </el-form-item>
    <el-form-item label="第三阶梯人数">
      <el-input-number :min="0" v-model="form.three_buy_num"></el-input-number>
    </el-form-item>
    <el-form-item label="团长奖励金额">
      <el-input-number :min="0" v-model="form.head_award_fee"></el-input-number>
      元
      <div class="y-desc" style="line-height: 1rem">仅自行邀请第二阶梯团、第三阶梯团成团的团长奖励，系统自动成团团长无奖励</div>
    </el-form-item>
    <el-form-item label="卡券领取">
      <!--      <el-radio v-model="form.coupon_func" :label="1">自动领取</el-radio>-->
      <el-radio v-model="form.coupon_func" :label="2">手动领取</el-radio>
      <el-radio v-model="form.coupon_func" :label="3">审核发放</el-radio>
    </el-form-item>
    <el-form-item label="首页展示">
      <el-radio v-model="form.index_show" :label="1">关闭</el-radio>
      <el-radio v-model="form.index_show" :label="2">开启</el-radio>
    </el-form-item>
    <el-form-item align="right">
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "mode4",
  props: {
    active_id: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    active_id(e) {
      if (e > 0) {
        this.edit_id = e;
        this.load()
      }
    },
  },
  mounted() {
    if (this.active_id > 0) {
      this.edit_id = this.active_id;
      this.load();
    }
  },
  data() {
    return {
      edit_id: 0,
      form: {
        mode: 4,
        title: "",
        time_range: [],
        time_start: "",
        time_end: "",
        fee: 0,
        double_buy_fee: 0,
        double_buy_num:0,
        three_buy_fee: 0,
        three_buy_num:0,
        head_award_fee: 0,
        coupon_func: 2,
        index_show: 1
      },
      loading: false,
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$api.active.one({id: this.edit_id}).then(res => {
        res.time_range = [res.time_start, res.time_end];
        this.form = res;
        this.loading = false;
      })
    },
    onSubmit() {
      this.form.time_start = this.form.time_range[0];
      this.form.time_end = this.form.time_range[1];
      this.form.fee = parseFloat(this.form.fee);
      if (!this.form.id) {
        this.$api.active.add(this.form).then((res) => {
          this.$message.success("操作成功");
          this.$emit("next", res)
        })
        return;
      }
      this.$api.active.update(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>